.review-container {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 85%;
    background: #e1ede6;
    overflow-x: scroll;
    overflow-y: visible;
    padding: 15px;
    border-radius: 0;
    margin-bottom: 50px;
    position: static;
}

.review-container::-webkit-scrollbar {
    display: none;
}

.review {
    display: block;
    flex-shrink: 0;
    width: calc((100% - 90px) / 3);
    height: auto;
    background: #a5c3b1;
    margin: 15px;
    position: static;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 0;
    transition: 0.4s;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1200px) and (min-width: 700px) {
    .review {
        width: calc((100% - 60px) / 2);
    }
}

@media (max-width: 700px) {
    .review {
        width: calc(100% - 30px);
    }
}

.review:hover {
    transform: scale(1.02);
}

.review-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.review-photo img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.review-name {
    font-size: 16pt;
    font-weight: 700;
    color: #333333;
    margin-left: 20px;
    margin-bottom: 10px;
}

.review-divider {
    width: 85%;
    height: 1.5px;
    background: #1f1f1e;
    border-radius: 1px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.3;
}

.review-text {
    font-size: 12pt;
    font-weight: 500;
    text-align: left;
    margin-left: 30px;
    margin-right: 20px;
    color: #333333;
}