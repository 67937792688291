@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  margin:0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-color: #1f1f1e;
}

.Body, div, a, p, img {
  border: 0 solid black;
  border-radius: 0;
}

.Body {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: #111111;
  font-family: Montserrat;
  font-weight: 600;
}

#Block_1 {
  display: flex;
  flex-direction: column;
  background-image: none;
  background-repeat: no-repeat;
}

#Title_0 {
  color: #05953C;
  font-size: max(6vw, 28pt);
  font-weight: 800;
  margin-top: calc(60px + 30px);
  margin-bottom: 30px;
}

#Img_1 {
  width: 70%;
  margin: 20px auto 10px;
  pointer-events: none;
}

#nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 50px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 22px;
  margin: 10px auto;
  color: rgba(256, 256, 256, 0.95);
  background: #05953C;
  transition: 0.4s;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
}

#nav:hover {
  background: #0CA848;
  transform: scale(1.02);
}

#nav, a {
  font-weight: 500;
  text-decoration: none;
}


#nav_apk {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 20px;
  height: 30px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 18px;
  margin: 5px auto 10px;
  color: rgba(256, 256, 256, 0.95);
  background: rgba(0, 0, 0, 0.4);
  transition: 0.4s;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
}

#nav_apk:hover {
  background: #05953C;
  transform: scale(1.02);
}

#nav_apk, a {
  font-weight: 500;
  text-decoration: none;
}

.Text {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 70px;
}

.Block_column {
  display: flex;
  flex-direction: column;
  margin: 20px 20px 50px;
  width: min(1000px, 90vw);
}

.Title_block_Col {
  text-align: center;
  font-size: min(4.6vw, 30pt);
  font-weight: 700;
}

.Subtitle_block_Col {
  text-align: center;
  font-size: min(3vw, 22pt);
  font-weight: 600;
}

.Descript_block_Col {
  text-align: center;
  font-size: min(2.1vw, 16pt);
  font-weight: 400;
  margin-top: 15px;
}

.Communication_block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.ButtonForm {
  width: auto;
  margin: 10px;
  background: #aac0e8;
  padding: 10px 50px;
  cursor: pointer;
  border-radius: 30px;
  color: #333333;
  transition: 0.4s;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  font-family: Montserrat, serif;
  font-weight: 500;
  font-size: calc(10px + 1.5vmin);
  border: none;
}

.ButtonForm:hover {
  background: #738ec0;
  transform: scale(1.01);
}

#Hand {
  width: 350px;
  margin-left: 30px;
}

@media (max-width: 650px) {
  .Communication_block {
    flex-direction: column;
  }

  #Hand {
    margin-left: 0;
    margin-top: 40px;
    width: 50%;
    min-width: 250px;
  }
}

#Class_frame {
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  width: min(1000px, 90vw);
  justify-content: space-between;
  margin-bottom: 70px;
}

.Frame {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min(300px, 27%);
  padding: 0;
  border-radius: 0;
}

.a1 {
  width: 2px;
  background-color: #444444;
  border-radius: 1px;
}

#Part {
  list-style: none;
  display: block;
  float: left;
  text-align: center;
  margin-bottom: 40px;
  margin-right: 70px;
  margin-left: 70px;
}

#Part img {
  height: 30px;
  margin: 15px;
}

#Part li {
  display: inline;
  cursor: pointer;
}

#Line_down {
  width: 60%;
  height: 1.5px;
  border-radius: 0.75px;
  background-color: black;
  opacity: 0.3;
  margin-bottom: 5px;
}

#Text_down {
  font-size: 10pt;
  opacity: 0.6;
  margin-bottom: 10px;
}

#Contacts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: min(90vw, 1000px);
  margin-bottom: 40px;
  margin-top: 20px;
}

#Contacts_block {
  width: 40%;
  margin-left: 20px;
  display: flex;
  color: #444444;
  flex-direction: column;
  justify-content: center;
}

#Map_0 {
  display: block;
  overflow: hidden;
  width: 60%;
}

#Map, iframe {
  width: 100%;
}

#Map_b1 {
  color: #eee;
  font-size: 12px;
  position: absolute;
  top: 0;
}

#Map_b2 {
  color: #eee;
  font-size: 12px;
  position: absolute;
  top: 14px;
}

#Map, iframe {
  border: solid black 0;
  border-radius: 15px;
  height: 400px;
  allowFullScreen: true;
}

.Contact_block {
  align-items: center;
  vertical-align: middle;
  border-radius: 15px;
  border: solid black 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hhh {
  font-weight: 600;
  color: #444444;
  transition: 0.3s;
}

.hhh:hover {
  color: #05953C;
}

.B {
  width: 5px;
  height: 5px;
  background-color: #444444;
  border-radius: 50%;
  margin: auto;
}

.Anchor {
  position: absolute;
  top: -100px;
}

@media (max-width:650px) {
  #Title_0 {
    margin-top: calc(60px + 70px);
  }

  #Block_1 {
    display: flex;
    flex-direction: column;
  }

  #Img_1 {
    width: 98%;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
  }

  #nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 185px;
    height: 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 25px;
    font-size: 18px;
    margin-right: auto;
    margin-left: auto;
    color: rgba(256, 256, 256, 0.95);
    background: #05953C;
    transition: 0.4s;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
  }

  #nav:hover {
    background: #0CA848;
    transform: scale(1.02);
  }

  #nav, a {
    font-size: 12pt;
    font-weight: 500;
    text-decoration: none;
  }

  #nav_apk {
    height: 25px;
    font-size: 12px;
  }

  #nav_apk:hover {
    background: #0CA848;
    transform: scale(1.02);
  }

  #nav_apk, a {
    font-weight: 500;
    text-decoration: none;
  }

  .Text {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 70px;
  }

  .Block_column {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin: 20px;
    width: min(1000px,90vw);
  }

  .Title_block_Col {
    text-align: center;
    font-size: min(26pt);
    font-weight: 700;
  }

  .Subtitle_block_Col {
    text-align: center;
    font-size: min(14pt);
    font-weight: 600;
    margin-bottom: 15px;
  }

  .Descript_block_Col {
    text-align: center;
    font-size: min(10pt);
    font-weight: 400;
  }

  #Part {
    list-style: none;
    display: block;
    float: left;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
    margin-right: 5vw;
    margin-left: 5vw;
  }

  #Part img {
    height: 15px;
    margin: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #Part li {
    display: inline;
    cursor: pointer;
  }

  #Line_down {
    width: 60%;
    height: 1.5px;
    border-radius: 0.75px;
    background-color: black;
    opacity: 0.3;
    margin-bottom: 5px;
  }

  #Text_down {
    font-size: 8pt;
    opacity: 0.6;
    margin-bottom: 10px;
    margin-right: 5vw;
    margin-left: 5vw;
  }

  #Contacts {
    font-size: 12pt;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: min(90vw, 1000px);
    margin-bottom: 40px;
    margin-top: 20px;
  }

  #Contacts_block {
    width: 95%;
    margin-left: 20px;
    display: flex;
    color: #444444;
    flex-direction: column;
    justify-content: center;
  }

  #Map_0 {
    margin-top: 20px;
    display: block;
    overflow: hidden;
    width: 90%;
    height: 200px;
  }

  #Map, iframe {
    width: 100%;
  }

  #Map_b1 {
    color: #eee;
    font-size: 12px;
    position: absolute;
    top: 0;
  }

  #Map_b2 {
    color: #eee;
    font-size: 12px;
    position: absolute;
    top: 14px;
  }

  #Map, iframe {
    border: solid black 0;
    border-radius: 15px;
    height: 400px;
    allowFullScreen: true;
  }

  .Contact_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    border-radius: 15px;
    border: solid black 0;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .hhh {
    margin-left: 15px;
    font-weight: 600;
    color: #444444;
    transition: 0.3s;
  }

  .hhh:hover {
    color: #05953C;
  }

  .B {
    display: none;
  }
}