#Header_body {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
}

#Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: rgba(256, 256, 256, 0.5);
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
}

#Media_block {
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
}

#Logo_s {
    height: 60%;
    margin-left: 20px;
}

#Link_block {
    position: fixed;
    right: 0;
    font-family: Montserrat, serif;
    display: flex;
    flex-direction: row;
    text-align: right;
    margin-right: 50px;
}

.Link {
    text-decoration: none;
    font-weight: 600;
    margin: 1vw;
    color: #444444;
    transition: 0.3s;
    font-size: 14px;
}

.Link:hover {
    color: #05953C;
}

#Header_border {
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.full-width-block {
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(25, 25, 25, 0.5);
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
    z-index: 3;
    transition: height 0.3s ease;
    overflow: hidden;
    height: 60px; /* Начальная высота блока */
}

.full-width-block.expanded {
    height: 180px; /* Высота блока при раскрытии списка */
}

.full-width-block.collapsed {
    height: 60px; /* Высота блока при скрытии списка */
}

.toggle-button {
    /*border: solid 1px red;*/
    width: 100px;
    background-color: transparent;
    color: #444444;
    display: none;
}

.items-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none; /* Список скрыт по умолчанию */
    flex-direction: column;
    justify-content: center;
}

.items-list.show {
    display: flex; /* Показать список */
}

.LanguageSwitcher {
    top: 15px;
    right: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
    font-size: 14px;
}

.ButtonSwitcher {
    background: #eeeeee;
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
    color: white;
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 1px transparent;
    transition: 0.2s;
}

.ButtonSwitcher:hover {
    background: #e1e1e1;
}

.ButtonSwitcher img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 70%;
}

.MenuSwitcher {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    background: white;
    width: 100px;
    padding: 10px;
    height: 90px;
    border-radius: 12px;
    border: solid 1px #e1e1e1;
}

.ButtonLanguage {
    border: none;
    width: 90%;
    height: 24px;
    margin: auto;
    cursor: pointer;
    border-radius: 6px;
    background: #f9f9f9;
    color: #444444;
    font-weight: 600;

}

.ButtonLanguage:hover {
    background: #f3f3f3;
    color: #05953C;
}

@media (min-width: 651px) {
    .full-width-block {
        height: auto; /* Высота блока адаптируется под содержимое */
    }

    .items-list {
        flex-direction: row; /* Элементы в строчку */
    }

    .toggle-button.hide {
        display: none; /* Скрыть кнопку на больших экранах */
    }
}

@media (max-width: 650px) {
    .toggle-button {
        display: block; /* Показать кнопку на маленьких экранах */
    }

    .full-width-block.expanded .items-list {
        display: flex; /* Показать список при активации */
        flex-direction: column; /* Элементы вертикально */
    }

    #Link_block {
        width: 200px;
        position: fixed;
        top: 60px;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
    }

    .Link {
        margin: 10px 45px 10px 10px;
    }

    .MenuSwitcher {
        width: 120px;
        height: 80px;
        border-radius: 12px;
    }

    .ButtonLanguage {
        height: 30px;
        font-size: 14px;
    }

    #Menu_button {
        position: fixed;
        right: 20px;
        background-color: transparent;
        /*margin: 30px;*/

        border: none;
        cursor: pointer;
        font-size: 14px;
        margin-right: 10px;

        font-weight: 600;
        color: #444444;
        transition: 0.2s;
    }

    #Menu_button:hover {
        color: #05953C;
    }
}
