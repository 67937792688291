.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 95%;
    max-width: 500px;
    max-height: 300px;
    border-radius: 50px;

    color: #333333;
    background: #e1ede6;
}

.modal a {
    text-decoration: none
}

.Input_block {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 330px;
}

.Buttons_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 330px;
}

.Title {
    font-size: min(26px, 6vw);
    font-weight: 700;

    margin-top: 30px;
    margin-bottom: 25px;
}

.Input {
    height: 40px;
    width: 100%;
    border-radius: 20px;

    border: 0;
    color: #333333;
    background: #a5c3b1;

    margin-bottom: 20px;
}

.Input[type="text"] {
    font-size: 16pt;
}

.Button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 47%;
    height: 40px;
    cursor: pointer;
    border-radius: 20px;

    color: #333333;
    background: #a5c3b1;
    border: none;
    font-family: Montserrat, serif;
    font-size: 20px;
    font-weight: 500;

    transition: 0.5s;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3)
}


.Buton:hover {
    background: #6ea086;

    transform: scale(1.05);
}












